export const RTC_ERROR_CODES = {
  NOT_SUPPORTED_BROWSER: -1,
  WEBSOCKET_ERROR: 1,
  ROOM_JOIN_FAILURE: 2,
  MAX_RETRIES_EXCEEDED: 3,
  CONNECTION_FAILED: 4,
}

export const RTC_STATES = {
  ERROR: -1,
  DISCONNECTED: 0,
  CONNECTING: 1,
  CONNECTED: 2,
  JOINING: 3,
  JOINED: 4,
  WAITING: 5,
}

export const LIVE_ERROR_CODES = {
  SOCKET: 1,
  RTC: 2,
  STORAGE: 3,
  BROWSER: 4,
};

export const USER_ROLES = {
  ADMIN: "admin",
  SUPERADMIN: "superadmin",
  OPERATOR: "admin-operator",
  SPEAKER: "speaker",
}

export const getTextByState = (state, retries) => {
  switch (state) {
    case RTC_STATES.ERROR: return "Connection Error";
    case RTC_STATES.DISCONNECTED: return "Disconnected";
    case RTC_STATES.CONNECTING: return "Connecting" + (retries ? ", Retry #" + retries : "");
    case RTC_STATES.WAITING: return "Waiting for streams";
    case RTC_STATES.CONNECTED: return "Connected";
    // case RTC_STATES.JOINING: return "Joining";
    // case RTC_STATES.JOINED: return "Connected";
    default: return "Disconnected";
  }
}

const gray = "#757575";
const red = "#f44336";
const yellow = "#ff9800";
const green = "#4caf50";

export const STATUS_NAMES = {
  CREATED: 0,
  UPLOADED: 1,
  SLIDES_APPROVED: 2,
  SLIDES_DECLINED: 3,
  SLIDES_PROCESSED: 4,
  READY_FOR_RECORDING: 5,
  RECORDING_CHECKED: 6,
  RECORDING_EDITED: 7,
  READY_FOR_DELIVERY: 8,
  RECORDING_EXPORTED: 9,
  FINAL_CHECK: 10,
}

export const sessionStatuses = [
  { id: 0, name: "Created", progressItems: [{ value: 0, color: gray }] },
  { id: 1, name: "Uploaded", },
  { id: 2, name: "Slides approved", },
  { id: 3, name: "Slides declined", },
  { id: 4, name: "Slides checked", progressItems: [{ value: 0, color: gray, name: "Slides to be checked", useForFilter: true, }] },
  { id: 5, name: "Ready for recording", progressItems: [{ value: 0, color: green, name: "Ready for recording" }] },
  {
    id: 6,
    name: "Recording checked",
    progressItems: [
      { value: 0, color: gray, name: "Recording to be checked" },
      { value: 1, color: red, name: "Recording check: Awaiting information from client" },
      { value: 2, color: yellow, name: "Recording check: Received information from client" },
    ]
  },
  {
    id: 7,
    name: "Recording edited",
    progressItems: [
      { value: 0, color: yellow, name: "Recording to be edited", isCircle: true },
      { value: 1, color: yellow, name: "Editing in progress" },
    ]
  },
  {
    id: 9,
    name: "Recording exported",
    progressItems: [
      { value: 0, color: yellow, name: "Recording to be exported", isCircle: true },
      { value: 1, color: yellow, name: "Export in progress", excludeFromFilter: true },
    ]
  },
  { 
    id: 10, 
    name: "Final check done",
    progressItems: [
      { value: 0, color: yellow, name: "Final check", isCircle: true },
      { value: 1, color: yellow, name: "Final check in progress" },
    ]
  }, 
  {
    id: 8,
    name: "Ready for delivery",
    progressItems: [
      { value: 0, color: green, name: "Ready for delivery", isCircle: true },
      { value: 1, color: red, name: "Declined by client" },
      { value: 2, color: green, name: "Approved by client" },
      { value: 3, color: green, name: "Delivery not required" },
    ]
  },
];

const manualSteps = [[5], [6], [7], [9], [10], [8]];
export const defaultWorkflow = [[0], [4], ...manualSteps];
export const approvalWorkflow = [[0], [1], [2, 3], [4], ...manualSteps];
export const manualPushWorkflow = [[0], [1], [4], ...manualSteps];

export const workflows = { defaultWorkflow, approvalWorkflow, manualPushWorkflow };

export const getWorkflow = config => config.hasApproval ? workflows.approvalWorkflow : (config.autoPushForSpeakers ? workflows.defaultWorkflow : workflows.manualPushWorkflow);

export const CAMERA_CODES = {
  SCREEN_SHARE: "share_screen",
  NONE: "none",
}